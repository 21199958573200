import useJwt from '@/auth/jwt/useJwt'
import ability from '@/libs/acl/ability'
import { initialAbility } from '@/libs/acl/config'

export default [
  {
    path: '/not-authorized',
    name: 'not-authorized',
    component: () => import('@/views/pages/authentication/NotAuthorized.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    title: 'Login',
    component: () => import('@/views/pages/authentication/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/logout',
    name: 'auth-logout',
    title: 'Logout',
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
    beforeEnter() {
      window.$cookies.remove(useJwt.jwtConfig.storageTokenKeyName)
      window.$cookies.remove('userData')

      // Reset ability
      ability.update(initialAbility)

      // Redirect to login page
      window.location.replace('/login')
    },
  },
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    meta: {
      requiresAuth: true,
      resource: 'all',
    },
  },
  {
    path: '/driver/orders',
    name: 'driver-orders',
    component: () => import('@/views/driver/Orders.vue'),
    meta: {
      requiresAuth: true,
      resource: 'all',
    },
  },
  {
    path: '/drivers',
    name: 'check-driver',
    component: () => import('@/views/admin/Check.vue'),
    meta: {
      requiresAuth: true,
      resource: 'all',
    },
  },
  {
    path: '/driver-payments',
    name: 'driver-payments',
    component: () => import('@/views/admin/PendingPayments.vue'),
    meta: {
      requiresAuth: true,
      resource: 'all',
    },
  },
  {
    path: '/spy-login',
    name: 'spy-login',
    component: () => import('@/views/admin/SpyLogin.vue'),
    meta: {
      requiresAuth: true,
      resource: 'all',
    },
  },
  {
    path: '/merchant/orders',
    name: 'merchant-orders',
    component: () => import('@/views/merchant/Orders.vue'),
    meta: {
      requiresAuth: true,
      resource: 'all',
    },
  },
  {
    path: '/merchant/report',
    name: 'merchant-report',
    component: () => import('@/views/merchant/Report.vue'),
    meta: {
      requiresAuth: true,
      resource: 'all',
    },
  },
  {
    path: '/uber/integration',
    name: 'merchant-uber-integration',
    beforeEnter() {
      window.location.replace('https://login.uber.com/oauth/v2/authorize?response_type=code&client_id=izEO4DvGWgBp8WeDDbju5pqAywNtCtg_&redirect_uri=https://app.eatz.pt/uber/integration/callback')
    },
  },
  {
    path: '/uber/integration/callback',
    name: 'merchant-uber-integration-callback',
    component: () => import('@/views/merchant/UberIntegration.vue'),
    meta: {
      requiresAuth: true,
      resource: 'all',
    },
  },
]
