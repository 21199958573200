/* eslint-disable no-undef */
import 'babel-polyfill'
import Vue from 'vue'

import VueCookies from 'vue-cookies'
import * as VueGoogleMaps from 'vue2-google-maps'
import moment from 'moment'
import { currency } from 'vuetensils/src/filters'
import VueI18n from 'vue-i18n'
import i18n from '@/libs/i18n'
import App from './App.vue'
import router from './router'
import store from './store'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/toastification'

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_KEY,
    libraries: 'places',
  },
})
Vue.filter('currency', currency)

Vue.use(VueCookies)
Vue.use(VueI18n)

// filters
Vue.filter('formatDate', (value => {
  if (!value) {
    return ''
  }
  return moment.utc(String(value)).format('DD/MM/YYYY HH:mm')
}))

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false
i18n.locale = (localStorage.getItem('lang') || 'pt_BR')

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
