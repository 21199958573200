export default [
  // companies
  {
    path: '/admin/companies',
    name: 'admin-companies',
    component: () => import('@/views/admin/Companies.vue'),
    meta: {
      requiresAuth: true,
      resource: 'all',
    },
  },
  {
    path: '/admin/companies/:id/edit',
    name: 'admin-companies-edit',
    component: () => import('@/views/admin/CompaniesEdit.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      resource: 'all',
    },
  },
  {
    path: '/admin/companies/new',
    name: 'admin-companies-new',
    component: () => import('@/views/admin/CompaniesEdit.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      resource: 'all',
    },
  },
  // stores
  {
    path: '/admin/stores',
    name: 'admin-stores',
    component: () => import('@/views/admin/Stores.vue'),
    meta: {
      requiresAuth: true,
      resource: 'all',
    },
  },
  {
    path: '/admin/stores/:id/edit',
    name: 'admin-stores-edit',
    component: () => import('@/views/admin/StoresEdit.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      resource: 'all',
    },
  },
  {
    path: '/admin/stores/new',
    name: 'admin-stores-new',
    component: () => import('@/views/admin/StoresEdit.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      resource: 'all',
    },
  },
  // users
  {
    path: '/admin/users',
    name: 'admin-users',
    component: () => import('@/views/admin/Users.vue'),
    meta: {
      requiresAuth: true,
      resource: 'all',
    },
  },
  {
    path: '/admin/users/:id/edit',
    name: 'admin-users-edit',
    component: () => import('@/views/admin/UsersEdit.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      resource: 'all',
    },
  },
  {
    path: '/admin/users/new',
    name: 'admin-users-new',
    component: () => import('@/views/admin/UsersEdit.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      resource: 'all',
    },
  },
  // drivers
  {
    path: '/admin/drivers',
    name: 'admin-drivers',
    component: () => import('@/views/admin/Drivers.vue'),
    meta: {
      requiresAuth: true,
      resource: 'all',
    },
  },
  {
    path: '/admin/drivers/:id/edit',
    name: 'admin-drivers-edit',
    component: () => import('@/views/admin/DriversEdit.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      resource: 'all',
    },
  },
  {
    path: '/admin/drivers/new',
    name: 'admin-drivers-new',
    component: () => import('@/views/admin/DriversEdit.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      resource: 'all',
    },
  },
  // merchants
  {
    path: '/admin/merchants',
    name: 'admin-merchants',
    component: () => import('@/views/admin/Merchants.vue'),
    meta: {
      requiresAuth: true,
      resource: 'all',
    },
  },
  {
    path: '/admin/merchants/:id/edit',
    name: 'admin-merchants-edit',
    component: () => import('@/views/admin/MerchantsEdit.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      resource: 'all',
    },
  },
  {
    path: '/admin/merchants/new',
    name: 'admin-merchants-new',
    component: () => import('@/views/admin/MerchantsEdit.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      resource: 'all',
    },
  },
  // orders
  {
    path: '/admin/stores/orders',
    name: 'admin-orders',
    component: () => import('@/views/admin/Orders.vue'),
    meta: {
      requiresAuth: true,
      resource: 'all',
    },
  },
  {
    path: '/admin/stores/orders/:id/edit',
    name: 'admin-orders-edit',
    component: () => import('@/views/admin/OrdersEdit.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      resource: 'all',
    },
  },
  {
    path: '/admin/stores/orders/new',
    name: 'admin-orders-new',
    component: () => import('@/views/admin/OrdersEdit.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      resource: 'all',
    },
  },
]
