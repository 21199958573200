export default {
  // Endpoints
  loginEndpoint: `${process.env.VUE_APP_CORE_ENDPOINT}/login`,
  spyLoginEndpoint: `${process.env.VUE_APP_CORE_ENDPOINT}/admin/spylogin`,
  registerEndpoint: '/jwt/register',
  refreshEndpoint: `${process.env.VUE_APP_CORE_ENDPOINT}/refresh-token`,
  logoutEndpoint: `${process.env.VUE_APP_CORE_ENDPOINT}/logout`,

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'access_token',
  storageRefreshTokenKeyName: 'access_token',

  accessTokenExpiration: 12000,
}
