import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { requiresAuth } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import administration from './routes/administration'
import pages from './routes/pages'

Vue.use(VueRouter)

const routes = [
  ...administration, ...pages,
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes,
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (requiresAuth(to) && !isLoggedIn) {
    return next({ name: 'auth-login' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
