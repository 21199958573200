<template>
  <div id="app" ref="App" class="h-100">
    <browser-check />
    <component :is="layout">
      <router-view />
    </component>
    <div id="pageLoading">
      <div class="loader" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
#pageLoading {
  display:none;
  z-index: 99999;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255,.8);

  .loader {
    top: calc(50% - 15px);
    left: calc(50% - 15px);
    position: absolute;
    border: 6px solid #f3f3f3; /* Light grey */
    border-top: 6px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 2s linear infinite;
  }
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>

<script>
// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import useAppConfig from '@core/app-config/useAppConfig'

import { useCssVar } from '@vueuse/core'

import OrdersEvent from './events/OrdersEvent'
import DriverGeoUpdateEvent from './events/DriverGeoUpdateEvent'
import LoadingEvent from './events/LoadingEvent'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

const BrowserCheck = () => import('@core/components/BrowserCheckComponent.vue')

const Pusher = require('pusher-js')

Pusher.logToConsole = false

export default {
  components: {
    // Layouts
    LayoutVertical,
    LayoutFull,

    BrowserCheck,
  },
  data() {
    return {
      pusher: null,
      channelOrders: null,
      channelDriverGeoUpdate: null,
    }
  },
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  mounted() {
    window.OrdersEvent = new OrdersEvent()
    window.DriverGeoUpdateEvent = new DriverGeoUpdateEvent()
    window.LoadingEvent = new LoadingEvent()
    this.pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, { cluster: 'eu' })

    this.channelOrders = this.pusher.subscribe('orders')
    this.channelOrders.bind('orders', data => {
      window.OrdersEvent.fire('Orders', data)
    })

    this.channelDriverGeoUpdate = this.pusher.subscribe('driverGeoUpdateEvent')
    this.channelDriverGeoUpdate.bind('driverGeoUpdateEvent', data => {
      window.DriverGeoUpdateEvent.fire('driverGeoUpdateEvent', data)
    })
  },
  setup() {
    const { skin, skinClasses } = useAppConfig()
    const { enableScrollToTop } = $themeConfig.layout

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    return {
      skinClasses,
      enableScrollToTop,
    }
  },
}
</script>
